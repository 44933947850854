import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../../Footer/Footer';
import { Col, Container, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import Header from '../Header';
import ButtonWithSpinner from '../../../Utils/ButtonWithSpinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Login = ({ login, password, error, loading, onChange, handleLogin }) => {
    const { messages } = useSelector((state) => state.locale);

    return (
        <div className="security login container-fluid">
            <Header />
            <Container>
                <Row className="justify-content-center form-container">
                    <Col>
                        <form
                            className="form--white-text"
                            onSubmit={handleLogin}
                        >
                            <p className="login__title">
                                <FormattedMessage id="security.login.title" />
                            </p>
                            <input
                                className="login__input"
                                value={login}
                                onChange={onChange}
                                name="login"
                                placeholder={messages.security.login.login}
                                type="text"
                                required
                            />
                            <input
                                className="login__input"
                                type="password"
                                required
                                value={password}
                                onChange={onChange}
                                name="password"
                                placeholder={messages.security.login.password}
                            />
                            <div className="login__sign-up">
                                {error && (
                                    <p className="inline-message__error">
                                        {error}
                                    </p>
                                )}
                                <ButtonWithSpinner
                                    label="security.login.sign_up"
                                    isLoading={loading}
                                    type="submit"
                                    className="btn-brand"
                                />
                            </div>
                        </form>
                        <Link
                            className="security__url"
                            to="/security/password-recovery/request"
                        >
                            <FormattedMessage id="security.login.forgot_password" />
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Footer inverted />
        </div>
    );
};

Login.propTypes = {
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default Login;
